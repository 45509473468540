.ServiceDetailPage{
    padding: 10%;
    padding-top: 160px;
    height:80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    padding-bottom: 60px;
    margin-bottom: 10vh;
}
.Service-Detail-Button{
    width:120px;
}
.Service-Detail-Button-Text{
    text-align: left;
    font-size: 18px;
}
.Service-Detail-Button-Text a{
    width:100%;
    text-align: left;
}
.SD-ServiceTitle{
    width:100%;
    text-align: left;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    color: var(--pb-dark);
    margin-bottom: 20px;
}
.SD-ServiceSloagan{
width:100%;
text-align: left;
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 25px;
letter-spacing: -0.02em;
text-transform: capitalize;
color: #6E45EF;
margin-bottom: 20px;

}
.SD-ServiceDescription{
width:100%;
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 30px;
text-align: justify;
letter-spacing: -0.02em;
text-transform: capitalize;
color: var(--pb-dark);
margin-bottom: 10px;

}
@media  screen and (max-width:1024px) {
   .ServiceDetailPage{
    height: fit-content;
    margin-bottom: 10vh;
   } 
}
@media  screen and (max-width:512px) {
    .SD-ServiceDescription{
        margin-bottom: 20px;
    }
 }