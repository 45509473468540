.marquee-container {
    width: 100%;
    overflow: hidden;
  }
  
  .marquee {
    display: inline-block;
    white-space: nowrap;
    animation: marquee-scroll 3s linear infinite; /* Adjust speed as needed */
  }
  
  .marquee-item {
    padding-right: 2rem; /* Adjust spacing between items */
  }
  
  @keyframes marquee-scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  