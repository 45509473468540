.WeExistSection{
    width:100%;
    max-width: 80vw;
    height:50vh;
    min-height: 300px;
    max-height: 500px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
}
.WES-Text-Container{
    width:60%
}
.WES-Text{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
}
.WES-Text-Dark,.WES-Text-Purple{

font-family: 'Montserrat';
font-style: normal;
font-weight: 800;
font-size: 35px;
letter-spacing: -0.02em;
text-transform: capitalize;

}


.WES-Text-Sloagan{
margin-top:30px;
font-family: 'Catamaran';
font-style: normal;
font-weight: 500;
font-size: 25px;
color:var(--pb-dark);

}
.WES-Text-Dark{
    color: var(--pb-dark);
}
.WES-Text-Purple{
    color:var(--purple-color);
}
.WES-Text-Dark,.WES-Text-Purple,.WES-Text-Sloagan{
    text-align: left;
    margin-bottom: 25px;
}
.WES-LetsCollab{
    display: flex;
flex-direction: row;

align-items: center;
padding: 0px;
isolation: isolate;

width: 330px;
height: 80px;


/* Inside auto layout */
flex: none;
order: 2;
flex-grow: 0;
z-index: 2;
}
.WES-LetsCollabText{
    width: 200px;
    height: 50px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 67px;
    /* identical to box height, or 313% */
    text-align: center;
    text-transform: capitalize;
    background-color: var(--dark-Background);
    border-top-left-radius: 16px;
    border-bottom-left-radius:16px;
    color: var(--pb-white);
    transition: all .5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}
.WES-LetsCollabIcon{
    border-top-right-radius: 16px;
    border-bottom-right-radius:16px;
    width: 60px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all .5s ease-in-out;
}
.WES-Images{
    position: relative;
    display: flex;
    align-items: center;
    width:30%;
    height: 300px;
}
@keyframes char1_animation{
    0%{

        opacity: 1;
    }
    50%{
        opacity:0
    }
    100% {
        opacity: 1;

    }
}
@keyframes char2_animation{
    0%{

        opacity: 0;
    }
    50%{
        opacity:1
    }
    100% {
        opacity: 0;


    }
}
.WES-char1{
    position: absolute;
    animation: char1_animation 7s ease infinite;
}
.WES-char2{
    position: absolute;
    animation: char2_animation 7s ease-in-out infinite;
}
.WES-char1 img,.WES-char2 img{
    width:100%;
    height: 100%;
    object-fit: scale-down;
}

@media screen and (max-width:1024px) {

    .WeExistSection{
        margin-top: 0px;
        flex-direction: column-reverse;
        justify-content: center;
        height:90vh;
        min-height:70vh;
        max-height:none;
        margin-bottom: 50px;
    }
    .WES-Text-Sloagan{
        margin-bottom: 0px;
    }
    .WES-Images{
        margin-top: 60px;
        margin-bottom: 30px;
        width:100%;
        height:50vh;
        min-height: 40vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .WES-char1,.WES-char2{
        margin-top: 0px;
        height: 50vh;
    }
    .WES-Text-Container{
        width: 100%;
    }
    .WES-Text-Dark{
        margin-bottom: 20px;
    }
    .WES-Text-Purple{
        margin-bottom: 20px;
    }
    .WES-Text-Sloagan{
        margin-top: 10PX;
    }
}
@media screen and (max-width:512px) {

    .WeExistSection{
        min-height:700px ;
        margin-bottom: 10px;

    }
}

.WES-char1-DM{
    display:none;
}
.WES-char2-DM{
    display:none;
}
@media (prefers-color-scheme: dark) {
    .WES-char1-LM{
        display:none;
    }
    .WES-char1-DM{
        display:block;
    }
    .WES-char2-LM{
        display:none;
    }
    .WES-char2-DM{
        display:block;
    }
}