@import url('DB_Requests_Latest.css');
@import url('DB_Requests_List.css');
.DB-Requests-Container{
    width: 100%;
    height:100%;
    display:flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
}
.DB-Requests-Block{
    width:49%;
    height:47%;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(215, 222, 228);
    transition: all 1.1s ease-in-out;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 20px;
    opacity:1;
}
.DB-clickedBlock{
    width:95%;
    height:95%;
    
}
.DB-remainedBlocks{
    width:0px;
    height:0px;
    overflow: hidden;
    opacity:0;
    font-size: 1px;
}
