.WhoWeAre{
    width:80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

    margin-bottom: 10vh;
}
.WhoWeAreText{
    
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 82px;
    text-align: justify;
    color: #000000;
    margin-bottom: 64px;

}
.WWA-LetsCollaborate{

    display: flex;
    flex-direction: row;
    
    align-items: center;
    padding: 0px;
    isolation: isolate;
    
    width: 330px;
    height: 80px;
    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;
    z-index: 2;
    width: 264px;
    background-color: var(--dark-Background);
    border-top-left-radius: 16px;
    border-bottom-left-radius:16px;
    border-top-right-radius: 16px;
    border-bottom-right-radius:16px;
    padding-right: 10px;
    transition: all .5s ease-in-out;

}

.WWA-LetsCollaborate:hover{
    background-color: var(--purple-color);
}
.WWA-LetsCollaborateText{
    width: 224px;
    height: 67px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 21.3333px;
    line-height: 67px;
    /* identical to box height, or 313% */
    text-align: center;
    text-transform: capitalize;

    color: var(--pb-white);
}
.WWA-LetsCollaborateIcon{
    width: 20%;
    height: 67px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all .5s ease-in-out;
}
.WWA-LetsCollaborateIcon svg path{
    stroke:var(--pb-white);
}
@media  screen and (max-width:1024px) {
    .WhoWeAreText{
        font-size: 24px;
    }
}