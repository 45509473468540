@import url('HeroSection.css');
@import url('HeroSection2.css');
@import url('LoopingText.css');
@import url('WhoWeAre.css');
@import url('WeExistSection.css');
@import url('WeAreSection.css');
@import url("ServiceBar.css");
@import url('Services.css');
.LandingPage{
    width:100vw;
    min-height: 100vh;
    margin-top:150px ;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}