.Careers{
    padding: 10%;
    padding-top: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.CRR-Title-1, .CRR-Title-2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 60px;
    text-align: left;
    text-transform: capitalize;
    color: var(--pb-dark);
}
.CRR-Title-2{
    font-weight: 600;
    font-size: 31px;
}
.CRR-row{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width:100%;
    margin-bottom: 30px;
}
.CRR-input{
    width:45%;
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    
}
.CRR-Input-Label{
    width:50%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 64px;
    text-transform: capitalize;
    color: var(--pb-dark);
    text-align: left;
    margin-left: 20px;
}
.CRR-input input{
    width:50%;
    height:30px;
    border: 0;
    background-color: transparent;
    border-bottom: 1px solid var(--pb-dark);
    color:var(--pb-dark);
    font-size:16px;
}
.CRR-input-file::-webkit-file-upload-button {
    /* Style for WebKit browsers like Chrome and Safari */
    font-weight: 600;
    color: var(--pb-dark);
    background-color: #eae0f1;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    color:#443e48;
    cursor: pointer;
  }
  .CRR-input-file::-webkit-file-upload-button:hover {
    background-color: #c19eda;


  }
.CRR-input input:focus{
    outline:none;
}
.CRR-input input[type='file']{
    border-bottom: 0px solid var(--pb-dark);

}

.CRR-row-button-container{
    display: flex;
    justify-content: flex-end;
} 
@media screen and (max-width:512px) {
    
}