.BlogPosts{
    min-height: 100vh;
    margin-top: 70px;
    padding: 2%;

}
.BP-Slgn{
    padding-left: 20px;;
    font-size: 42px;
    font-weight: 500;
    text-align: start;
}
.BP-Topics{
    margin-left: 20px;
    margin-top: 20px;
    display:flex;
    width:70%;
    justify-content: space-between;
}
.BP-Topic{
    padding: 5px;
    font-size: 14px;
    font-weight:600;
    border-bottom: 1.5px solid black;

}
.BP-Topic:hover{
background-color: var(--main-color-dark);    
}
.BlogPostsContainer{
    display: flex;
}
.BP-Latest{
    width:50%;
    padding: 20px;
}
.BP-Paginated{
    width:50%;
    padding: 20px;
}
.BP-Posts{
    width:100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}
.BP-Posts-Bx{
    width:90%;
    height: 50%;
    overflow: hidden;
}
.BP-Posts-Bx .PostCard .PostCard-image img{
    height:150px ;
    object-fit: cover;
}
.BP-Posts-Bx .PostCard-topic{
    font-size: 12px;
}
.BP-Posts-Bx .PostCard-title{
    font-size: 14px;
}