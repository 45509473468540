/* PaginatedArticleList.css */

.paginated-article-list {
    /* Add any styles for the container of your paginated list */
    height: 100%;
  }
  
  .pagination {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 10px 0;
    justify-content: center;
  }
  
  .page-number {
    cursor: pointer;
    padding: 8px 12px;
    margin-right: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .page-number:hover {
    background-color: #eee;
  }
  
  .page-number.active {
    background-color: var(--secondary-color);
    color: #fff;
  }
  