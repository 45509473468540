._BlogPosts{
    padding-top: 100px;
    margin-top: 100px;
    font-family: 'Montserrat';
    min-height: 150vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:20px;

}
.s-title{
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 36px;
font-weight: 600;

}
.l-title{
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 36px;
color:var(--purple-color);
font-weight: 600;
}
.m-sloagan{
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 36px;
    margin-bottom: 8px; 
}
._Posts{
    width:80vw;
    max-width: 1300px;
    min-height: 100px;
}
/*BlogPostCard*/
._Posts{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 40px;;
}
._BlogPostCard{
    width:400px;
    max-width: 33%;
    height:250px;
    background-color:var(--dark-Background);
    color:var(--dark-Background);
    font-size: 20px;
    font-weight: 500;
    font-style: oblique;
    position:relative;
    margin-bottom: 40px;

}

@media screen and (max-width:1024px) {

    ._Posts{
        justify-content: center;
    }
    ._BlogPostCard{
        width:90%;
        max-width: 90%;
    }
}
._BlogPostCardImage,._BlogPostCardTitle{
    /*position:absolute;*/
}
._BlogPostCardImage{
    width:100%;
    height:100%;
}
._BlogPostCardImage img{
    width:100%;
    height: 100%;
    object-fit: cover;
}
._BlogPostCardTitle{
    bottom: 30px;
    left:10px;
    text-align: left;
    margin-top:16px; ;
    font-size: 20px;
    display: flex;
    gap:8px;
    align-items: center;

}