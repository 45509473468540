.AboutUs{
    margin-top:100px ;
    display: flex;
    flex-direction: column;
    padding: 10%;

}
.AboutUs-Section1,.AboutUs-Section2{
    display: flex;
    width:100%;
    justify-content: space-evenly;

}
.AboutUs-Section1-Titles{
    width:60%;
    display: flex;
    flex-direction: column;
    align-items:start;

}
.AU-Title{
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 35px;
line-height: 65px;
display: flex;
align-items: center;
text-transform: capitalize;
color: var(--pb-dark);
text-align: left;
}
.AU-S1-Sloagan{
width:100%;
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 35px;
/* identical to box height, or 188% */
display: flex;
align-items: center;
text-transform: capitalize;
color: #6E45EF;
text-align: left;

}
.AboutUs-S1-P{
    width:40%;
}
.AboutUs-S1-P,.AboutUs-S2-P{
    
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 35px;
    display: flex;
    align-items: center;
    text-align: justify;
    text-transform: capitalize;

    color: var(--pb-dark);
}
.AboutUs-Section2{
    margin-top: 60px;
}
.AboutUs-Section2-Text{
    width:60%;
    display: flex;
    flex-direction: column;
    align-items: start;
}
.AboutUs-Section2-Image{
    width:40%;
    padding-left: 30px;
}
.AboutUs-Section2-Image img{
    width:100%;
    height: 100%;
    object-fit: scale-down;
}
@media  screen and (max-width:1024px) {
    .AboutUs-Section1{
        flex-direction: column;
    }
    .AboutUs-Section2{
        flex-direction: column-reverse;
    }
    .AboutUs-Section1-Titles,.AboutUs-S1-P,.AboutUs-Section2-Text,.AboutUs-Section2-Image{
        width: 100%;
    }
    .AboutUs-S1-P{
        margin-top: 40px;
    }
    .AboutUs-Section2-Image{
        width:100%;
    }
    .AboutUs-Section2-Image img{
        width:70%;
        margin-left: -70px;
        margin-top: -20px;
        margin-bottom: 40px;
    }
}
@media screen and (max-width:512px) {
    .AU-Title{
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 45px;
    }
    .AU-S1-Sloagan{
        margin-top: 10px;
        font-weight: 500;
        font-size: 25px;
        letter-spacing: -0.02em;
        text-transform: capitalize;
    }
    .AboutUs-S1-P,.AboutUs-S2-P{
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        text-align: justify;
    }
    .AboutUs-Section2-Image{
        display: flex;
        align-items: center;
        justify-content: center;

    }
    .AboutUs-Section2-Image img{
        width:70%;
        margin-left: -70px;
        margin-top: -20px;
        margin-bottom: 20px;
    }
    .AboutUs-S1-P {
        margin-top: 20px;
    }
}