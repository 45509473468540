
.WeAreSection{
    width:100%;
    max-width: 80vw;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:space-around;
    padding-top: 20px;
}
.WAS-P{
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 40px;
display: flex;
align-items: center;
/*text-align: justify;*/
text-transform: capitalize;

color: var(--pb-dark);
margin-bottom: 40px;


}
.WAS-HireUs{

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    isolation: isolate;
    width: 130px;
    flex: none;
    order: 2;
    flex-grow: 0;
    z-index: 2;
    transition: all .5s ease-in-out;
}
    .WAS-HireUs:hover{
        transform: scale(1.05);
        cursor: pointer;
    }
    .WAS-HireUsText{
        width: 70%;
        height: 45px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 67px;
        text-align: center;
        text-transform: capitalize;
        border-bottom-left-radius:20px;
        color:var(--pb-dark);
        transition: all .5s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .WAS-HireUsIcon{
        border-top-right-radius: 20px;
        width: 20%;
        height: 45px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: all .5s ease-in-out;    
    }
    .WAS-HireUsIcon svg{
        transition: all .9s ease-in-out;    

    }
    .WAS-HireUsIcon svg path{
        stroke: var(--icon-button-stroke);
    }
    .WAS-HireUs:hover .WAS-HireUsText{
        color: var(--purple-color-button);
        }
    
    .WAS-HireUs:hover svg path{
        stroke: var(--icon-button-stroke-hover);
    }