.DashBoardMenu{
    padding: 10px;
    background-color:var(--pb-white);
    height: 80vh;
    width:60px;
    position:absolute;
   /* left:-280px;*/
    top:0px;
    margin-top: 130px;
    transition: all 1s ease-in-out;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-radius: 20px;
    display:flex;
    flex-direction: column;
    align-items: baseline;
    margin-left: 10px;
    left:0px

}
.ShowDashBoardMenu{
    left:0px;
    width:250px
}


.DB-Table-Link{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    width:80%;
    text-align: left;
}
.DBM-Item svg{
    width:20%;
    min-width:60px;
}
.DBM-Item{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width:100%;
    height:50px;
    overflow: hidden;
    transition: all .5s ease-in-out;

}

.DBM-Item-Hoverable:hover{
    transform: scale(1.02);
    background-color: var(--purple-color-lighter);
    color:var(--pb-white);
}
.DB-Menu-Item-Text{
    overflow: hidden;
    width:1px;
    transition: width 1.1s ease-in-out;
}
.DB-Menu-Item-Text-Display{
    width:100px;

}