.ContactPage{
    padding: 10%;
    padding-top: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.CP-Title-Container{
    margin-bottom: 30px;
}
.CP-Title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 60px;
    text-align: left;
    text-transform: capitalize;
    color: var(--pb-dark);
}
.CP-P{
    width:80%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 40px;
    text-transform: capitalize;
    color: var(--pb-dark);
    text-align: justify;
}
.CP-Input-Container{
    margin-top: 64px;
    width:100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.CP-Input-Box{
    width:45%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.CP-Input-Label{

font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 64px;
text-transform: capitalize;
color: var(--pb-dark);

}
.CP-Input{
    width:50%;
    height:40px;
    border: .5px solid #9b969ea8;;
    border: 0;
    background-color: transparent;
    border-bottom: 1px solid var(--pb-dark);
    color:var(--pb-dark);
    font-size:16px;

}
.CP-Input:focus{
    outline:none;
}
.CP-Input-Select{
    width:50%;
    height:40px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-transform: capitalize;
    color: var(--pb-dark);
    position: relative;

}

.RequirementDropDown{
    display:none;
    position: absolute;
    top:45px;
    transition: all 1s ease-in-out;
    opacity:1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    gap: 4px;
    width: 320px;
    max-height: 300px;
    text-align: left;
    background: var(--pb-white);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 12px 12px 12px;

}
.ShowRequirements{
    opacity:1;
    display:flex;
}
.HideRequirements{
    display:none;
}

.CP-Input-Select-Label{
    width:130%;
    height:100%;
    font-size: 14px;
    /*border: 1px solid #443E48;*/
    border-radius: 5px;
    padding-left:10px;
    padding-right:10px;
    display: flex;
    align-items: center;
    justify-content:space-evenly;
    text-align: left;
    background-color: #eae0f1;
    color:#443e48;
}
.CP-Input-Select-Label:hover{
    background-color: #e2dae8;

}

.SubMenuReqElement{
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    padding: 10px;
    width: 300px;
    height: 40px;
    overflow: hidden;
    justify-content: space-between;
    border-radius: 10px;


    
}
    .SubMenuReqElement:hover{
        background-color:var(--cu-menu-hover-color);
    }
    .SubMenuReqElement:hover svg path{
        stroke:var(--purple-color);
    }
    .CP-Message-Details{
        margin-top: 64px;
        width:100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: left;
        overflow: hidden;

    }
    .CP-Textarea{
        width:100%;
        height: 350px;
        display: flex;
        align-items: left;

    }
    .CP-Textarea textarea{
        width:100%;
        border-radius: 20px;
        height: 300px;
        background-color: #443E48;
        color:#fff;
        resize: none;
        padding: 20px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 64px;
        border: 0px solid black;
    }
    .CP-Textarea textarea:focus{outline: none;}
    @media (prefers-color-scheme: dark) {
        .CP-Textarea textarea{
            background-color:#474747 ;
            color:#e7dcdc;
        }
    }
    .CP-Submit-Container{
        margin-top: 30px;
        display: flex;
        width:100%;
        justify-content: flex-end;

    }
    .CP-Submit{
        display: flex;
        align-items: center;
        width:120px;
        cursor: pointer;
        transition:all .5s ease-in-out;
    }
    .CP-Submit:hover{
        transform: scale(1.05);
    }
    .CP-Submit-Text{
        height:40px;
        border-bottom-left-radius: 20px;
        color:var(--pb-dark);
        width:70%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 49px;
        transition: all .5 ease-in-out;
    }
    .CP-Submit-Icon{
        height:40px;
        width:20%;
        border-top-right-radius: 20px;
        color:var(--pb-white);
        display: flex;
        align-items: center;
        justify-content: center;

    }
    .CP-Submit-Icon svg{
        margin-top: 5px;
        height:15px;
        width:30px;
        object-fit: fill;
        transition: all .9s ease-in-out;
    }
    .CP-Submit-Icon svg path{
        stroke: var(--icon-button-stroke);
    }
    .CP-Submit:hover .CP-Submit-Text{
        color:var(--purple-color-button);
    }
    .CP-Submit:hover svg path{
        stroke: var(--icon-button-stroke-hover);

    }
    @keyframes CP_Submit_Text_animation{
        0%{
    
            background-color: #443E48;
        }
        50%{
            background-color: var(--purple-color-button);
        }
        100% {
            background-color: #443E48;
    
    
        }
    }
    @keyframes CP_Submit_Icon_animation{
        0%{
    
            background-color:var(--purple-color-button);
        }
        50%{
            background-color: var(--purple-color-lighter);
        }
        100% {
            background-color:var(--purple-color-button);
    
    
        }
    }
    @media screen and (max-width:1024px) {
        .CP-Input-Container{
            flex-direction: column;
        }
        .CP-Input-Box{
            width:100%;
        }
    }
    @media screen and (max-width:512px) {
        .CP-Title-Container{
            margin-bottom: 15px;
        }
        .CP-Title{
            width: 100%;
        }
        .CP-P{
            font-size: 20px;
            line-height: 30px;
            font-weight: 500;
            width:100%;
            margin-top: 0px;
        }
        .CP-Input-Container{
            margin-top: 32px;
        }
    }
    @media screen and (max-width:420px) {
        .CP-Input-Box{
            flex-direction: column;
        }
        .CP-Input{
            width:100%;
        }
        .CP-Input-Select{
            width:100%;
        }
    }