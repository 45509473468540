.Main{
    height: 100%;
    width:98.8vw;
    overflow: hidden;
    background-color: var(--background-color);

}
.Under{
    width:100vw;
    height: 100vh;
    font-weight: 900;
    font-size: 40px;
}
.No-Main-Scroll{
    height:130vh;
}
a{
    cursor: pointer;
}
@media screen and (max-width:1024px) {

    .Main{
        width:100vw;
        overflow-x:hidden;
    }
}
