/*Fonts*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@100..900&display=swap');
@import url('./Menu/Menu.css');
@import url('./Main.css');
@import url('./Footer.css');
@import url('./LandingPage//LandingPage.css');
@import url('./Pages/Pages.css');
@import url('./AdminPanel/AdminPanel.css');
@import url('./Blog/Blog.css');
:root{
    --purple-color:#6e45ef;
    --purple-color-button:#6e45ef;
    --purple-color-lighter:#b8a2ff;
    --pb-desktop-menu-hover-color:#f5d39f;
    --pb-yellow:#f5d39f81;
    --shadow-white-color:#f2e6fb;
    --pb-white:#ebebeb;
    --dark-Background:#272529;
    --dark-Background:#222024;/*test*/

    --pb-dark:#443E48;
    --background-color:rgb(242, 242, 242);
    --icon-button-stroke:#443E48;
    --icon-button-stroke-hover:#6e45ef;
    --mobile-menu-stroke:#190923;
    --icon-stroke:#443E48;;
    background-color: rgb(242, 242, 242);
    --cu-menu-hover-color: #e7dfef;
    ;

}
a{
    text-decoration: none;
    color: inherit;
}
@media (prefers-color-scheme: dark) {
    
    :root{
    --purple-color-button:#ffd518;
    --purple-color-lighter:#caac25;
    --pb-desktop-menu-hover-color:#cebc1b;
    --background-color:#242424;


    --pb-dark:#ebebeb;
    --pb-white:#443e48;
    --mobile-menu-stroke:#ebebeb;
    --icon-stroke:#ebebeb;
    --cu-menu-hover-color: #5e4e6c;
    --icon-button-stroke:#ebebeb;
    --icon-button-stroke-hover:#ffd518;
    --pb-yellow:#b8a2ff;

    }
}
.UMain{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 0px;
gap: 32px;
padding: 5%;
padding-top: 0%;
padding-bottom: 1%;
height: 100vh;
}
.UMain svg{
width:200px;
height: 200px;
}
.Title1{
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 48px;

text-align: center;

color: var(--pb-dark);
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;

}
.Title2{
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 40px;
text-align: center;

color: var(--purple-color);
flex: none;
order: 2;
align-self: stretch;
flex-grow: 0;

}