.HeroSection{
    
    margin-top:60px;
    width:70vw;
    max-width:1500px;
    overflow: hidden;
    height:auto;
    height:50vh;
    min-height: 300px;
    max-height: 500px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content:space-around;
    width:100%;
    max-width: 80vw;
    display: flex;
    justify-content: space-between;

}
.WeAreSection{
    padding-top: 0px;
    width:60%;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    position: relative;
}

.HS-Title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: var(--pb-dark);
}
.HS-Title-WR{
    margin-right: 10px;

}
.HS-Sloagan{
width: 100%;
font-family: 'Catamaran';
font-style: normal;
font-weight: 500;
font-size: 25px;
color: var(--pb-dark);
margin-bottom: 30px;
text-align: left;

}
.HS-Title-Bottom{
    margin-bottom: 30px;
}

.PurpleTitle{
    color:var(--purple-color);
    
}
.PurpleTitle2{
    color:var(--purple-color);
    font-size: 22px;
    font-style: italic;
    font-weight: 500;

}
.GetStartedButton{
    margin-top:5px;
    padding: 15px 35px;
    border: 2px solid var(--secondary-color);
    background-color: var(--main-color);
    color:var(--secondary-color);
    border-radius: 50px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13.285px;
    flex-shrink: 0;
    transition: all .5s ease-in-out;
    cursor:pointer;
    font-weight: bold;


}

.GetStartedButton a{
    color:var(--secondary-color);

}
.GetStartedButton:hover a{
    color:var(--main-color);

}
.GetStartedButton:hover{
    color: var(--main-color);
    background-color:var(--secondary-color);

}
@keyframes char1_animation{
    0%{

        opacity: 1;
    }
    50%{
        opacity:0
    }
    100% {
        opacity: 1;


    }
}
@keyframes char2_animation{
    0%{

        opacity: 0;
    }
    50%{
        opacity:1
    }
    100% {
        opacity: 0;


    }
}
.HS-Text-Section{
    width:50%;
}
.HS-Image-Section{
    width:40%;
    min-height:70vh ;
    
}
.HS-char1{
    position: absolute;
    animation: char1_animation 7s ease infinite;
}
.HS-char2{
    position: absolute;
    animation: char2_animation 7s ease-in-out infinite;
}
.HS-char1 img,.HS-char2 img{
    width:70%;
    height: 70%;
    object-fit: scale-down;
}

/*animation*/
@keyframes downUpdown{
    0%{
        transform:translateY(-30px);
        display:none;
        opacity: 0;
        height: 0%;
    }
  50% {
        transform:translateY(6px);
        display:inline;
        opacity:1;
        height: 100%;

    }
    100% {
        transform:translateY(-30px);
        display:none;
        opacity: 0;
        height: 0%;


    }
}
@keyframes upDownup{
    0%{
        transform:translateY(3px);
        display:inline;
        opacity: 1;
        height: 100%;


    }
  50% {
        transform:translateY(30px);
        display:none;
        opacity:0;
        height: 0%;


    }
    100% {
        transform:translateY(3px);
        display:inline;
        opacity: 1;/**/
        height: 100%;


    }
}
.WAT{
    display: flex;
    flex-direction: row;
    align-items: center;
    width:100%;
    height:60px
}
.WAT>h1{
    min-width:170px;
}
.aC{
    position: relative;
    height:100%;
    min-width:170px;
    padding-left: 5px;
    margin-top: 10px;
}
.aC2{
    position: relative;
    min-width:170px;
    padding-left: 5px;
    height: 100px;
}
.aC2 h1{
    margin-top:0px;
    margin-bottom: 0px;
}
.infiniteDownUp{
    position: absolute;
    animation: downUpdown 4s ease  infinite;
}
.infiniteUpDown{
    position: absolute;
    animation: upDownup 4s ease  infinite;
}

@media  screen and (max-width:1024px) {
    .HeroSection{
        margin-top: 120px;
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        height: auto;
        justify-content: space-between; 
        align-items: baseline;   
        height:90vh;
        min-height:700px;
        max-height:none;
        margin-bottom: 10px;
    }
    .HS-Text-Section{
        width:100%;
        justify-content: Center;
    }

    .HS-Title-Bottom{
        margin-bottom: 10px;
    }

    .HS-Sloagan{
        margin-bottom: 10px;

    }
    .HS-Image-Section{
        margin-bottom: 30px;
        width:100%;
        height:50vh;
        min-height: 40vh;
        display: flex;
        align-items: center;
        justify-content: center;

    }
    
    .HS-char1,.HS-char2{
        margin-top: -130px;
        height: 50vh;
        WIDTH:100%;
    }
    .HS-char1 img,.HS-char2 img{
        margin-left: 10px;
        width:100%;
        height: 100%;
        object-fit: scale-down;
    }


     
}

@media  screen and (max-width:512px) {
    .HeroSection{
        margin-top: 50px;
        min-height:700px ;
    }
    .WAT{
        flex-direction: COLUMN;
        margin-bottom: 50px;
        display: flex;
        align-items:baseline;
    }
    .HS-Title-Bottom{
        margin-bottom: 10px;
    }
    .HS-Image-Section{
        margin-top: 30px;
    }
    .HS-char1 img,.HS-char2 img{
        margin-top: 100px;
        margin-left: -10px;
        width:80%;
        height: 80%;
        object-fit: scale-down;
        
    }
    .PurpleTitle{
        color:var(--purple-color);
        margin-left: -6px;
    }
    .HS-Sloagan{
        margin-bottom: 25px;
    }

}
.HS-char1-DM{
    display:none;
}
.HS-char2-DM{
    display:none;

}
@media (prefers-color-scheme: dark) {
    .HS-char1 img,.HS-char2 img{
        margin-left: 40px;

    }
    .HS-char1-LM{
        display:none;
    }
    .HS-char1-DM{
        display:block;
    }
    .HS-char2-LM{
        display:none;
    }
    .HS-char2-DM{
        display:block;
    }
}