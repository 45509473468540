.SingleReelPostContainer{
    padding: 2%;
    padding-top: 200px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:24px;
    padding-bottom: 50px;
}
.SingleReelPostContainer p{
    margin:0;
}
.SingleReelPost{
width:90%;
height:90vh;
display:flex;
justify-content: center;
align-items: center;
min-height: 600px;

}
.video-container {
    position: relative;
    width: 80%;
    height: 80%;
    overflow-x:hidden ;
    

  }
.SingleReelPostVideo{

    width: 70%;
    height: 100%;
}
.SingleReelPostVideoMobile{
    display:none;
    width: 70%;
    height: 100%;
}
.SingleReelPostBgPic{
    top:0;
    left:0;
    position :absolute;
    width:160%;
    height:100%;
    min-height: 600px;
    object-fit:cover;
}
.Post-Title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 35px;
    display: flex;
    align-items: center;
    text-align: left;
    text-transform: capitalize;
    color: var(--pb-dark);

    width:70%;
}
.Post-Text{
    width:70%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 35px;
    display: flex;
    align-items: center;
    text-align:left;
    text-transform: capitalize;

    color: var(--pb-dark);
    display:flex;
    flex-direction: column;
    gap:24px;
}
@media screen and (max-width:1024px) {
    .SingleReelPostVideo{
        display: none;
    }
    .SingleReelPostVideoMobile{
        display:block;
    }
    .Post-Title{
        font-size: 24px;

    }
}