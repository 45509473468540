.ServiceBar{
    height:80px;
    width:90vw;
    display: flex;
    align-items: center;
    font-size: 16px;
    overflow: hidden;
    margin-bottom: 64px;
}
.ServiceBar .swiper-slide{
    width:200px
}
.ServiceBar-Text{
    height:50px;
    display: flex;
    align-items: center;
    justify-content: center;
  

font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 120px;
/* identical to box height, or 300% */
text-align: center;
letter-spacing: -0.02em;
text-transform: capitalize;
color: var(--pb-dark);
}