.LP-Services{
    margin-top: 40px;
    width:100%;
    max-width: 80vw;
    min-height: 30vh;
    margin-bottom: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    }
.LP-ServicesContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width:95vw;
    }

.LP-Services-Text{
    width:80%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: var(--pb-dark);
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 0;

}
.ServiceOverLayContainer{
    width:30vw;
    padding: 20px;;
    display: flex;
}
.ServiceIcon{
    width: 90px;
    height:90px;
    width:30%;
    z-index: 2;
    transition: all .3s ease-in-out;
}
@media (prefers-color-scheme: dark) {
    .ServiceIcon img{
        filter: invert(100%);
    }
}
.ServiceIcon img{
    width:100%;
    height: 100%;
    object-fit: scale-down;
}
.ServiceText{
    width:60%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: var(--pb-dark);
    z-index: 1;
    text-align: left;
    padding-left: 10px;
    position: relative;
    display: flex;
    justify-content: center;
}
.ServiceIcon:hover{
    transform: translateX(-10px);    
}
.ServiceCover{
    position: absolute;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    transform: scale(1);
    opacity: 1;
    transition: all 1s ease-in-out;

    left:-70px;
    padding-left:80px;
}
.ServiceData{
    height: 100%;
    width:100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: left;
    text-transform: capitalize;
    color: var(--pb-dark);
    overflow: hidden;
    background-color:var(--pb-white);
    transform: scale(0.9);
    opacity: 0;
    transition: all 1s ease-in-out;
    min-width:350px;
    overflow: hidden;
    border-radius: 16px;
    padding: 10px;
    left:-70px;
    padding-left:80px;


}
.ServiceOverLayHide{
    transform: scale(0);
    opacity: 0;
}
.ServiceOverLayShow{
    transform: scale(1);
    opacity: 1;
}

.LP-Services-Learn-More{

        position: absolute;
        right: -60px;
        bottom: -15px;
        text-decoration: underline;
        opacity: 0.55;
        display:flex;
        align-items: center;
        transition: all .3s ease-in-out;
        color:var(--pb-dark)

}
.LP-Services-Learn-More svg {
    opacity: 0;
}
.LP-Services-Learn-More svg path {
    stroke:var(--purple-color);
}
.LP-Services-Learn-More:hover{
    transform:scale(1.05);
    color:var(--purple-color-button) /*var(--purple-color)*/;
    opacity: 1;
    text-decoration: none;
}
.LP-Services-Learn-More:hover svg{
    opacity:1;
    
}
.LP-Services-Learn-More:hover svg path{
    stroke:var(--purple-color-button);
    
}
.LP-Services-Service-Title{
    width:100%;
    padding-left: 10px;
    margin-top: 0px;
}
@media screen and (max-width:1024px) {
    .LP-Services-Text{
        width:100%;}
.ServiceOverLayContainer{
    width:350px;
    min-width: 200px;
    height: 120px;
}
.ServiceOverLayContainer{
    padding-left: 0px;
}
.ServiceCover{
    height: 50px;
}
.ServiceIcon{
    width:80px;
    height: 80px;
}
.LP-Services-Learn-More{
    top:50px;
    right:-10px;
}
.ServiceData{
    width:280px;
    min-width: 100px;

}
.ServiceData div{
    width:250px;


}

.ServiceIcon img{
    width:100%;
    height: 100%;
    object-fit: scale-down;
}
.ServiceText{
    width:250px;
    font-size: 18px;

}
}
@media  screen and (max-width:1024px) {
    .LP-Services-Text{
        font-size: 20px;
    }
}