._LatestPost{
    position: relative;
    width:80vw;
    max-width: 1300px;
    height: 600px;
}

._LatestPostTitle{
    bottom: 15%;
    left: 30px;
    font-size: 18px;
    line-height: 36px;
    font-weight: 500;
    position: absolute;
    color:white;
}

._LatestPostImage{
    width:100%;
    height: 100%;
    position: absolute;
    background-color: black;
}
._LatestPostImage img{
    width:100%;
    height: 100%;
    object-fit:cover;
    opacity: .6;
}