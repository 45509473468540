
.LatestRequestData-Container{
    display:flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-around;

}
.LatestRequestData{
    margin: 10px;
    display:flex;
    align-items: center;
}