.HeroSection2{
width:100%;
max-width: 80vw;
min-height: 70vh;
margin-bottom: 10vh;
}
.HeroSectionMobile{
    margin-top: 40px;
    width:100%;
    max-width: 80vw;
    min-height: 70vh;
    margin-bottom: 10vh;
    }
.HeroSectionContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
}
.HR-Text{
    width:50%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}
.HS-Title-WR{

    width:fit-content
}
.HS-Title-Section{
    margin-bottom: 40px;
    width:100%;
}
.HR-H1{
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 82px;
    text-align: left;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    z-index: 0;
    margin-bottom: 64px;
    width:100%;
}
.purpleText{
    color:var(--purple-color);
}
.HR-H2{
    /* Where ideas take flight, creativity unleashed */
width:100%;
margin-bottom: 64px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 37px;
/* identical to box height */
display: flex;
align-items: center;
text-align: justify;
letter-spacing: -0.02em;
text-transform: capitalize;

color: #000000;


/* Inside auto layout */
flex: none;
order: 1;
flex-grow: 0;
z-index: 1;

}
.HR-Image{
    width:50%
}
.HR-Image img{
    width:100%;
    height: 100%;
    object-fit: scale-down;
}
.HR-GetStarted{
display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
width: 190px;
margin-bottom: 30px;
transition: all .5s ease-in-out;
height:fit-content;
position: relative;
z-index: 1;
}
.HR-GetStarted-link{
    cursor: pointer;
    }
.HR-GetStarted:hover{
    transform: scale(1.05);
}
.HR-GetStartedText{
width: 75%;
height: 45px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 67px;
text-align: center;
text-transform: capitalize;
color: var(--pb-dark);
transition: all .5s ease-in-out;
display: flex;
align-items: center;
justify-content: center;
}
.HR-GetStarted:hover .HR-GetStartedText{
    color:var(--purple-color)
}
.HR-GetStartedIcon{
border-top-right-radius: 20px;
width: 20%;
height: 45px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
.HR-GetStartedIcon svg{
    transition: all .5s ease-in-out;
}
.HR-GetStarted:hover .HR-GetStartedText{
    color:var(--purple-color-button)
}
.HR-GetStarted:hover svg path{
    stroke: var(--icon-button-stroke-hover);

}

.HR-GetStartedIcon svg path{
    stroke: var(--icon-button-stroke);
}

