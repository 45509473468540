.Footer{
    min-height:300px;
    width:100vw;
    display:flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: var(--dark-Background);
    padding-top: 20px;



}
.FT-LogoContainer {
    width:20%;
    padding-bottom: 20px;
}
.FT-LogoContainer img{
    width:60%;
    height:60%;
    object-fit: scale-down;
}

.FT-GetInTouch{
    width:95%;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px ;

}
.FT-GetInTouch-Container{
    width:70%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.FT-Socials-Container{
    width:20%;
}

.FT-sectionTitle{

font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 36px;
/* identical to box height, or 208% */
text-align: left;
letter-spacing: -0.02em;
text-transform: capitalize;

color: #FFFFFF;


flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;

}
.FT-sectionData{

/* Paragraph/desktop */
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 30px;
/* or 208% */
text-align: left;
letter-spacing: -0.02em;
text-transform: capitalize;

/* pink */
color: #E4D4F1;

}
.FT-sectionData div:hover{
cursor: pointer;
}
.FT-Input{

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px;
gap: 8px;
width: 250px;
height: 30px;
border:0px ;
background: rgba(228, 212, 241, 0.47);
color:#ebebeb;
font-weight: 600;
border-radius: 20px;

}
.FT-Input-Container{
    width:330px;
    display: flex;
    flex-direction: row;
    /*justify-content: space-between;*/
    align-items: center;
    gap:4px;
}
.Footer input::placeholder{
    color:#443e48;
    font-weight: 700;

}
.FT-InputIcon{
    position:absolute;
    transform: translateX(220px);
    z-index: 1;
}
.FT-Stay-Send circle{
    fill:#ebebeb;
    cursor: pointer;
}
.FT-Stay-Send circle:hover{
    fill:var(--purple-color-button);
    cursor: pointer;
}
.FT-Contact,.FT-Services,.FT-Newsletter{
    width:30%;
    height:100px;
}
.FT-Contact{
    text-align: left;
}
.FT-Socials{
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px ;

}
.FT-Newsletter{
    left:0;
}
.FT-Success{
    color: #ebebeb;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-left: -60px;

}
.FT-lower{
    margin-top: 10px;
   width:60%; 
   display:flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   flex-wrap: wrap;

}
.FT-RightReserved{
opacity:.7;
width:20%;
width: 315px;
height: 50px;
/* Paragraph/desktop */
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 36px;
/* identical to box height, or 208% */
display: flex;
align-items: center;
justify-content: center;
letter-spacing: -0.02em;
text-transform: capitalize;
color: #ebebeb;
}
@media screen and (max-width:1024px) {
    .Footer{
        height: fit-content;
    }
    .FT-GetInTouch-Container{
        width:40%;
        flex-direction: column;
        justify-content: baseline;
        align-items: baseline;
        padding-left: 20px;
    }
    .FT-Contact, .FT-Services, .FT-Newsletter, .FT-Socials {
        width:100%;
        height: fit-content;

    }
    .FT-Socials-Container{
        width:50%;
    }
    .FT-Menu{
        height: 300px;
    }
    .FT-Menu div{
        min-width:100px;
    }
    .FT-LogoContainer {
        width:70%;
    }
    .FT-Contact,.FT-Services{
        margin-bottom: 30px;
    }

}
@media screen and (max-width:500px) {
    .FT-GetInTouch{
        flex-direction: column;
        justify-content: center;
    }
    .FT-GetInTouch-Container{
        width:100%;
    }
    .FT-Socials-Container{
        width:100%;
        margin-top: 30px;
        margin-left: -15px;
    }
    .FT-Socials{
        justify-content: space-evenly;
    }
    
}