.SuccessfullRequestPage-Container{
    margin-top: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    width:100vw;
    margin-bottom: 20px;
}
.SuccessfullRequestPage-Text{
    width:50%;
    height: 450px;
    font-family: 'Montserrat';
    font-style: normal;
    text-align: justify;
    font-weight: 400;
    font-size: 30px;
    line-height: 75px;
    text-transform: capitalize;
    color: var(--pb-dark);
}
@media screen and (max-width:1024px) {
    .SuccessfullRequestPage-Text{
        width:80%;
        height:100%;
        font-size: 25px;
        line-height: 55px;
    }
}