.PostCard{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: start;
    width: 100%;
    padding: 0px 10px;
    border-right: 1px solid black;
    margin-bottom: 10px;
}
.PostCard-image{
    width:100%;
    height: 60%;
    max-height: 400px;
    overflow: hidden;
}
.PostCard-image img{
    width:100%;
    height: 100%;
    max-height: 400px;
    object-fit:cover;
}
.PostCard-topic{
    padding-top: 10px;
    font-size: 1vw;
    font-weight: 600;
    color:var(--quaternary-color)
}
.PostCard-title{
    text-align: left;
    padding-top: 10px;
    font-size: 20px;
    font-weight: 900;
    
}