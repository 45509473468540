.blogEditor{
    margin-top: 10%;
    color: var(--primary-color);
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center ;
    margin-bottom: 5%;
    padding-left: 10%;
    padding-right: 10%;
}
.blogEditor input{
    background-color: transparent;
    margin-bottom: 30px;
    border-radius: 20px;
}
.blogEditor input[type="text"]{
    width:50%;
    padding: 10px;
    border: .5px solid var(--secondary-color);

}
.blogEditor input[type="file"]::file-selector-button{
    background-color: var(--quaternary-color);
    color:var(--main-color);
}
.blogEditor input[type="file"]{
    text-align: center;
    color:var(--secondary-color);
}
.blogEditor select{
   width:50%;
   background-color: transparent;
   font-size: 18px;
   text-align: center;
   margin-bottom: 30px;
}
.blogEditor textarea{
    resize: none;
    width:80%;
    height: 100px;
    background-color: transparent;
}
.blogEditor textarea::placeholderr{
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: var(--secondary-color);
}
.blogEditor input[type="file"]{
    text-align: center;
    color:var(--secondary-color);
}
.quillContainer{
    width:80%;
    height: 50%;
}
.quill{
    height: 50%;
}
.be-button{
    background-color: var(--main-color);
    color:var(--secondary-color);
    border: 0px solid black;
    border-radius:20px;
    font-size: 20px;
    padding: 10px;
    width:120px;
    transition:all .5s ease-in-out;

  }
  .be-button:hover{
    transform: scale(1.01);
    background-color: var(--quaternary-color);
    color:var(--main-color);
    transition:all .5s ease-in-out;

    
  }
.BlogEditor-OutPut{
    display:flex;
    flex-direction: column;
}