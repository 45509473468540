
.DeskTopMenu{
    margin: 10px;
    position: fixed;
    top:-10px;;
    left:-10px;
    background-color:var(--background-color);
    width:100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 10;

}
.DeskTopMenuContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width:98%;
    height:120px;
 
}
.LogoContainer{
    width:14%;
    overflow: hidden;

}
.LogoContainer img{
    width:80%;
    height: 80%;
    object-fit: scale-down;
}
.MenuContainer{
    width:40%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.MenuContainer>div{
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
padding-top: 5px;
padding-bottom: 5px;
padding-left: 15px;
padding-right: 15px;
text-align: center;
letter-spacing: -0.02em;
text-transform: capitalize;
color: var(--pb-dark);
border-radius:10px;
flex: none;
order: 0;
flex-grow: 1;
transition: all .2s ease-in-out;
margin: 10px;

}
.PurpleHover:hover{
    background-color: var(--pb-desktop-menu-hover-color);
    cursor: pointer;
}
.ServicesDropDown{
    display:none;
    position: absolute;
    top:30px;
    left:0px;
    transition: all 1s ease-in-out;
    opacity:1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    gap: 4px;
    width: 358px;
    height: 300px;
    background: var(--pb-white);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 12px 12px 12px;

}
.ShowDropDown{
    opacity:1;
    display:flex;
}
.SubMenuElement{
margin: 0;
display: flex;
flex-direction: row;
align-items: center;
text-align: left;
padding: 10px;
gap: 10px;
width: 326px;
justify-content: space-between;
border-radius: 10px;

}
.SubMenuElement:hover{
    background-color: var(--cu-menu-hover-color);
    color:var(--pb-dark)
}
.SubMenuElement:hover svg path{
    stroke:var(--purple-color);
}
.ShadowHover{
    display:flex;
    align-items: center;
    position: relative;
}
.RightMenu{
    width:20%;
    display: flex;
    align-items: center;
    justify-content: center
    ;
}
.RightMenu div{
    /*
    width: 74px;
    height: 40px;*/
    color:var(--pb-dark);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;;
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 24px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    border-radius:10px;
    flex: none;
    order: 0;
    flex-grow: 1;
    transition: all .2s ease-in-out;
    margin: 10px;
}

.Contact-Us-Button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-weight: 900;
    gap: 10px;
    /*background: #443E48;*/
    border-radius: 8px;
    /*color:rgb(242, 242, 242)*/
}
.Contact-Us-Button:hover{
    background-color:var(--pb-desktop-menu-hover-color);
    cursor: pointer;
}
.ShadowHover:hover{
    background-color:var(--pb-desktop-menu-hover-color);
    }
@media screen and (max-width:1024px) {
        .DeskTopMenu{
            display:none;
        }
    }

.logoDM ,.logoDM-Container {
    display: none;
}
.logoLM-Container{
    display: block;
}
.logoDM-Container,.logoLM-Container{
    margin-top: 10px;
    height:130px;
    width:168px;
}

    @media (prefers-color-scheme: dark) {
        .logoLM,.logoLM-Container{
            display:none;
        }
        .logoDM,.logoDM-Container{
            display: block;
            display: flex;
            justify-content: center;
        }
    }