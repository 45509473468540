.DashBoard{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100vh;
    margin-top: 140px;
}

.DBM-Holder{
    width:90px;
    margin-left: 10px;
    transition: all .9s ease-in-out;
    border-radius: 20px;
    background-color:var(--pb-white);


}
.DBM-Holder-Display{
    width:275px;
    margin-left: 10px;
}
.DB-Menu{
    margin-top: 10px;
    margin-left: 10px;
    position:absolute;
    width:100%;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    justify-content:center;
    cursor: pointer;
    width:60px;
    height:40px;
    padding: 5px;
    border-radius: 10px;
    transition: all 1.1s ease-in-out;
}
.DB-Menu svg{
    width:60px;
    height: 100%;
    object-fit: scale-down;
    
}
.DB-Menu-Display{
    width:200px
}
.DB-Button-Text{
    overflow: hidden;
    width:1px;
    transition: width 1.1s ease-in-out;
}
.DB-Button-Text-Display{
    width:100px;

}
.DashBoard-Content{
    width:100vw ;
    border-radius: 20px;
    background-color:var(--pb-white);
    transition: all 1.1s ease-in-out;

}
.DashBoard-Content-width{
    width:calc(100vw - 280px);
}
.DBM-Holder-width{
    width:300px;
}