.Db-Requests-List{
    width:100%;
    height: 100%;
    padding: 20px;
    background-color: rgb(62, 7, 83);
    color:var(--pb-white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.RequestsData-Container{
    padding: 20px;
    width:100%;
    display:none;
    transition: all 1.1s ease-in-out;
}
.RequestsData-Container-Show{
    display:flex;
}
.Requests-List-RequestData{
    width:100%;
    display: flex;
    justify-content: space-between;
}