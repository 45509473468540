
.MobileMenu{
    position: fixed;
    width:100vw;
    top:0;
    /*left:5px;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 11;
    height: 0px;
    background-color: var(--background-color);


}
.MobileMenuContainer{

    display: flex;
    position: absolute;
    top:0;
    z-index: 11;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width:100%;
    height: 110px;
    background-color: var(--background-color);


}
.M-logoContainer{
    padding: 5px;
    padding-left: 20px;
    padding-top: 40px;
    width:30%;
    overflow: hidden;
    
}
.M-logoContainer img{
    width:70px;
    object-fit: scale-down;
}
.M-Icon-Container{
    margin-right:60px;
    margin-bottom: 60px;
    width:12%;
    overflow: hidden;
    height: fit-content;

}
#MM-Menu-Icon{
    margin-top: 10px;
}
.MM-Close{
    height: 50px;
   /* right:10px;
    bottom: 70px;*/
}
#MM-Close-Icon{
    margin-top: 20px;
    margin-left: 20px;
}
.MM-Menu,.MM-Close{
    position: absolute;
    transition: all 1s ease-in-out;
}
.MM-Menu-Icon-Before{
    z-index: 1;
    opacity:1;
    transform: rotate(0deg) scale(1);
}
.MM-Close-Icon-Before{
    opacity: 0;
    transform:  scale(0);
}
.MM-Menu-Icon-After{
    opacity:0;
    transform: rotate(60deg) scale(0);
}
.MM-Close-Icon-After{
    opacity:1;
    transform: scale(1);
}
.MM-Content-Container{
    padding-top: 200px;
    position: relative;
    overflow-y: scroll;
    right:-100vw;
    width:0vw;
    transition: all 1s ease-in-out;
    display: flex;
    flex-direction: column;
    height: 80vh;
    padding-bottom: 20px;
    background-color: var(--background-color);
}
.MM-Content{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: baseline;
    padding-left: 30px;
    width:100%;
    height:fit-content;
    background-color: var(--background-color);
}
.MM-Content-Text{
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 24px;
    text-transform: capitalize;
    color:var(--pb-dark);
    margin-bottom: 40px;
    text-align: left;
}
.MM-Content-Container-Show{
    width:100vw;
    right:0;
}
.ServicesDropDownMobile{
    margin-top: 20px;
    padding-top: 15px;
    padding-bottom: 10px;
    display:none;
    height: 0px;
   /* position: absolute;
    top:30px;
    left:0px;*/
    transition: all 1s ease-in-out;
    opacity:1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    width: 358px;
    background-color: var(--pb-white);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 12px 12px 12px;
}
.SubMenuElementMobile{
    width:320px;
    border-radius: 10px;
    text-align: left;
    padding-left: 30px;
    padding-bottom: 10px;;
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 33px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color:var(--pb-dark);

}
.SubMenuElementMobile:hover{
    background-color: var(--cu-menu-hover-color);
}
.ShowDropDown{
    opacity:1;
    display:flex;
    height: fit-content;
}
@media screen and (min-width:1024px) {
    .MobileMenu{
        display:none;
    }
}
.M-logoDM{
    display: none;
}
.M-logoLM{
    height: 120%;
}
.MM-Menu-Icon path{
    stroke:var(--pb-dark);
}
@media (prefers-color-scheme: dark) {
    .M-logoLM{
        display:none;
    }
    .M-logoDM{
        display: block;
        height: 90%;
    }
    .MM-Menu-Icon path{
        stroke:var(--icon-white-stroke);
    }
}