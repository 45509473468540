.PostContainer{
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-left: 10%;
    padding-right: 10%;
    
}
.Post-Topic{
    font-size: 12px;
    padding: 5px 20px;
    background-color: transparent;
    border: 2px solid var(--secondary-color);
    font-size: 14px;
    width: 200px;
    border-radius: 20px;
    transition: all .5s ease-in-out;
    cursor: pointer;
    margin-bottom: 20px;
}
.Post-Topic:hover{
    background-color: var(--secondary-color);
    color:var(--main-color);
  }
.Post-MainImage{
    height: 60vh;
    width:100%;
}
.Post-MainImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.Post-Content{
    width:100%; 
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Post-Content p{
    text-align: justify;
}
.Post-Content img{
    height: 500px;
    object-fit: cover; 
    


}
.Post-Content h1,.Post-Content h2,.Post-Content h3,.Post-Content h4 .Post-Content h5,.Post-Content h6{
    width:100%;
    text-align: left;
}